<template>
  <div>
    <modal
      className="w-full md:w-2/5 xl:w-3/10 pt-10"
      ref="modifyRepaymentPercentage"
    >
      <h4 class="text-xl font-bold mb-6">Modify Repayment Percentage</h4>

      <template v-if="modifyRepaymentPercentageForm.errors">
        <div class="alert alert-red-soft mb-10">
          <div class="alert-icon">!</div>
          <span>{{ modifyRepaymentPercentageForm.errors }}</span>
        </div>
      </template>

      <div class="flex flex-row items-center mb-6">
        <form-group
          v-model="modifyRepaymentPercentageForm.data.repayment_percentage.value"
          :form="modifyRepaymentPercentageForm"
          name="repayment_percentage"
          placeholder="Enter Repayment Percentage"
          class="rounded-sm text-xs text-blue-500 w-full"
        />
      </div>

      <div class="flex flex-row items-center">
        <button
          type="button"
          class="btn btn-md btn-red-soft mr-2"
          @click.prevent="$refs.modifyRepaymentPercentage.close"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-blue btn-md"
          @click.prevent="updateRepaymentPercentage"
        >
          <span v-if="modifyRepaymentPercentageForm.loading">Resolving</span>
          <span v-else>Confirm</span>
        </button>
      </div>
    </modal>
    <div class="text-lg font-bold mb-5">User Active Plan</div>
      <div class="grid grid-cols-3 xl:grid-cols-2 gap-2 mb-8">
        <div>
          <button
            type="button"
            class="btn btn-sm btn-green"
            @click.prevent="$refs.modifyRepaymentPercentage.open"
          >
            Modify Repayment Percentage
          </button>
        </div>
      </div>
        <div class="mb-10">
          <div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200">
            <div class="col-span-1 p-4">
              <div class="opacity-75 text-sm font-medium mb-1">Interest</div>
              <div
                class="text-gray-500 text-sm font-light flex flex-row items-center"
              >
                <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
                {{ activeUserPlanInterest }}
              </div>
          </div>
          <div
            class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200"
          >
            <div class="opacity-75 text-sm font-medium mb-1">
              Repayment Percentage
            </div>
            <div
              class="text-gray-500 text-sm font-light flex flex-row items-center"
            >
              <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
              {{ activeUserPlanRepaymentPercentage }}
            </div>
          </div>
        </div>

        <div
          class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200"
          style="margin-top: -1px"
        >
          <div class="col-span-1 p-4">
            <div class="opacity-75 text-sm font-medium mb-1">
              Start Date
            </div>
            <div
              class="text-gray-500 text-sm font-light flex flex-row items-center"
            >
              <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
              {{ activeUserPlanStartDate }}
            </div>
          </div>
          <div
            class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200"
          >
            <div class="text-sm font-medium mb-1 text-pink-500">
              End Date
            </div>
            <div
              class="text-pink-300 text-sm font-light flex flex-row items-center"
            >
              <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
              {{ activeUserPlanEndDate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    props: {
      staff: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        activeUserPlan: undefined,
        modifyRepaymentPercentageForm: this.$options.basicForm([
          { name: 'repayment_percentage', rules: 'required' },
        ]),
      }
    },
    computed: {
      activeUserPlanInterest() {
        return this.activeUserPlan?.interest ?? 'N/A';
      },
      activeUserPlanRepaymentPercentage() {
        return this.activeUserPlan?.repayment_percentage ?? 'N/A';
      },
      activeUserPlanStartDate() {
        return this.activeUserPlan?.start_date ?? 'N/A';
      },
      activeUserPlanEndDate() {
        return this.activeUserPlan?.end_date ?? 'N/A';
      }
    },
    beforeMount() {
      this.fetchActiveUserPlan();
    },
    methods: {
      async fetchActiveUserPlan() {
        const response = await this.sendRequest('admin.repayments.getActiveUserPlan', this?.staff?.id, {
        });
        this.activeUserPlan = response?.response?.data[0]
      },
      updateRepaymentPercentage() {
        if (!this.validateForm(this.modifyRepaymentPercentageForm))
          return;

          this.sendRequest('admin.repayments.updateRepaymentPercentageOnUserPlan', {
            data: {
              user_plan_id: this.activeUserPlan?.id,
              repayment_percentage: this.modifyRepaymentPercentageForm.data.repayment_percentage.value
            },
            success: () => {
          this.$success({
            title: 'Operation Successful',
            body: `Repayment Percentage updated successfully.`,
            button: 'Okay',
          });

          this.$emit('success');
          this.resetForm(this.modifyRepaymentPercentageForm);
        },

        error: error => {
          this.$error({
            title: 'Operation not successful',
            body: `${error.response.data.message || 'Unable to update the Repayment Percentage'}`,
            button: 'Okay'
          });
          this.modifyRepaymentPercentageForm.loading = false;
        }
          })
      }
    }
  };
  </script>
  